import React from 'react';
import EventBookingPlatform from './EventBookingPlatform';
import { AuthProvider } from './components/AuthContext';
import { LanguageProvider } from './LanguageContext';

function App() {
  return (
    <AuthProvider>
      <LanguageProvider> {/* Wrap EventBookingPlatform with LanguageProvider */}
        <div>
          <EventBookingPlatform />
        </div>
      </LanguageProvider>
    </AuthProvider>
  );
}

export default App;