import React, { useState, useEffect } from 'react';
import { LanguageProvider, useLanguage } from './LanguageContext';
import { translations } from './LanguageContext'; // Adjust the path as necessary
import { 
  Calendar, 
  Camera, 
  UtensilsCrossed, 
  PartyPopper, 
  Menu, 
  X, 
  HelpCircle, 
  User, 
  Copy, 
  Check, 
  Phone as PhoneIcon,
  Minus,
  Plus
} from 'lucide-react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import CheesyEventLines from './CheesyEventLines';
import OTPVerificationPopup from './OTPVerificationPopup';

import { AuthProvider, useAuth } from './components/AuthContext';
import { UserDropdown } from './components/UserDropDown';
import { UserProfile } from './components/AuthContext';
import { LoginModal}  from './components/LoginModal';


const Navigation = () => {
  // This component can be removed if not used elsewhere
  return null;
};



const NavItems = ({ activeItem, setActiveItem }) => {
  const navItems = [
    { name: 'Events', icon: PartyPopper },
    { name: 'Photography', icon: Camera },
    { name: 'Catering', icon: UtensilsCrossed },
  ];

  return (
    <>
      {navItems.map(({ name, icon: Icon }) => (
        <button
          key={name}
          onClick={() => setActiveItem(name)}
          className={`flex items-center space-x-3 px-6 py-3 rounded-lg text-lg transition-all duration-300 transform hover:scale-105 active:scale-95 ${
            activeItem === name
              ? 'bg-[rgb(252,123,3)] text-white'
              : 'bg-white text-black hover:bg-gray-100'
          }`}
        >
          <Icon size={24} className={activeItem === name ? 'text-white' : 'text-black'} />
          <span className="font-medium">{name}</span>
        </button>
      ))}
    </>
  );
};

const OfferCard = ({ offer, index }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(offer.tag);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div 
      className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:scale-102"
      style={{ 
        opacity: 0,
        animation: `fadeIn 0.5s ease-out ${index * 0.1}s forwards`
      }}
    >
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <span className="text-3xl">{offer.icon}</span>
          <div className="flex items-center space-x-2">
            <span className="text-sm font-medium px-3 py-1 bg-orange-100 text-[rgb(252,123,3)] rounded-full">
              {offer.tag}
            </span>
            <button
              onClick={handleCopy}
              className="text-gray-500 hover:text-[rgb(252,123,3)] transition-colors transform hover:scale-110 active:scale-90"
            >
              {copied ? <Check size={18} /> : <Copy size={18} />}
            </button>
          </div>
        </div>
        <h3 className="font-medium mb-2">{offer.title}</h3>
        <p className="text-sm text-gray-500">{offer.validity}</p>
      </div>
    </div>
  );
};

const Header = ({ isMenuOpen, setIsMenuOpen, activeItem, setActiveItem }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { user } = useAuth();

  const handleLoginClick = () => {
      setShowLoginModal(true);
  };

  return (
      <header className="border-b shadow-sm bg-white sticky top-0 z-50 animate-slideDown">
          <div className="max-w-7xl mx-auto px-4 py-6">
              <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-12">
                      <div className="h-12 w-40 transform hover:scale-105 transition-transform">
                          <img src="/shatalogo.png" alt="Shata Logo" className="h-full w-full object-contain" />
                      </div>
                      {/* Navigation Items */}
                      <nav className="hidden lg:flex space-x-8">
                          <NavItems activeItem={activeItem} setActiveItem={setActiveItem} />
                      </nav>
                  </div>

                  {/* Language and Help Buttons */}
                  <div className="hidden lg:flex items-center space-x-8">
                      <select className="bg-transparent border-none focus:ring-0 cursor-pointer text-lg">
                          <option>English</option>
                          <option>Hindi</option>
                      </select>
                      <button className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:text-[rgb(252,123,3)] text-lg transform hover:scale-105 transition-all">
                          <HelpCircle size={24} />
                          <span>Help</span>
                      </button>

                      {/* User Dropdown */}
                      <UserDropdown onLoginClick={handleLoginClick} />
                  </div>

                  {/* Mobile Menu Button */}
                  <button 
                      className="lg:hidden p-2 hover:bg-gray-100 rounded-lg transform hover:scale-110 active:scale-95 transition-all" 
                      onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                      {isMenuOpen ? <X size={28} /> : <Menu size={28} />}
                  </button>
              </div>

              {isMenuOpen && (
                  <div className="lg:hidden pt-4 pb-3 space-y-4 animate-slideDown">
                      {/* Mobile navigation items */}
                      <nav className="flex flex-col space-y-2">
                          <NavItems activeItem={activeItem} setActiveItem={setActiveItem} />
                          {/* Language selection for mobile */}
                          <select className="bg-transparent border-none focus:ring-0 cursor-pointer text-lg">
                              <option>English</option>
                              <option>Hindi</option>
                          </select>
                          {/* Help button for mobile */}
                          <button className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:text-[rgb(252,123,3)] text-lg transform hover:scale-105 transition-all">
                              <HelpCircle size={24} />
                              <span>Help</span>
                          </button>
                      </nav>

                      {/* User Dropdown for mobile */}
                      {user ? (
                          // Render UserDropdown for logged-in users
                          <>
                              <UserDropdown onLoginClick={handleLoginClick} />
                          </>
                      ) : (
                          // Login button for non-authenticated users
                          <button 
                              onClick={handleLoginClick} 
                              className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:text-[rgb(252,123,3)] text-lg transform hover:scale-[1.02] transition-all w-full"
                          >
                              <User size={24} />
                              <span>Login</span>
                          </button>
                      )}
                  </div>
              )}
          </div>

          {/* Login Modal */}
          {showLoginModal && (
              // Your modal component here
              // Example:
              <LoginModal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)} />
          )}
      </header>
  );
};


const EventBookingPlatform = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('Events');
 
const [isOtpPopupOpen, setIsOtpPopupOpen] = useState(false);
  
  const [selectedEventType, setSelectedEventType] = useState('');
  const [location, setLocation] = useState('');
  const [budget, setBudget] = useState('');
  const [servings, setServings] = useState('');
  const [date, setDate] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [openFaq, setOpenFaq] = useState(null);
  const eventTypes = [
    'Birthdays',
    'Surprises',
    'Family Functions',
    'Proposal',
    'Weddings',
    'Engagement',
    'Corporate Events',
    'Pre-release',
  ];

  const shootTypes = [
    'Maternity Shoot',
    'Pre-Wedding Shoot',
    'Wedding Shoot', 
    'Post-Wedding Shoot',
    'Baby Shower Shoot',
    'Self Portraits',
    'Professional Portraits',
  ];

  const cateringTypes = [
    'Wedding Buffet',
    'Plated Meal',
    'Food Stalls',
    'Cocktail Reception',
    'Live Counters'
  ];


  const locations = [
    'Guntur, Andhra Pradesh',
    'Hyderabad, Telangana',
    'Vizag, Andhra Pradesh',
  ];

  const trendingOffers = [
    {
      icon: '🎉',
      title: 'Save up to ₹5000 on Birthday packages',
      tag: 'EVENTS',
      validity: 'Valid till 30 Nov',
    },
    {
      icon: '🍽️',
      title: 'Save up to ₹3000 on Catering services',
      tag: 'CATERING',
      validity: 'Valid till 30 Nov',
    },
    {
      icon: '📸',
      title: 'Flat 25% off on Photography packages',
      tag: 'PHOTO',
      validity: 'Valid till 30 Nov',
    },
    {
      icon: '💝',
      title: 'Special discount on Surprise events',
      tag: 'EVENTS',
      validity: 'Valid till 30 Nov',
    },
  ];

  const faqs = [
    {
      question: "How does Shata's event booking process work?",
      answer: "Our booking process is simple and seamless. First, select your event type and location. Then, specify your budget and preferred date. Our platform will match you with the best vendors and venues that fit your criteria. Once you've made your selection, you can customize your package and confirm your booking with a secure payment."
    },
    {
      question: "What happens if I need to reschedule my event?",
      answer: "We understand that plans can change. You can reschedule your event up to 15 days before the scheduled date without any penalty. For changes within 15 days, a small rescheduling fee may apply. Simply contact our 24/7 support team, and we'll help you find a new date that works for you and your vendors."
    },
    {
      question: "Are there any hidden charges in the packages?",
      answer: "No, we believe in complete transparency. The price you see is the price you pay. All applicable taxes, service charges, and vendor fees are clearly displayed before you confirm your booking. If there are any additional optional services available, they will be clearly marked as such."
    },
    {
      question: "Can I customize my event package?",
      answer: "Absolutely! While we offer pre-designed packages for convenience, every aspect can be customized to match your vision. From decor themes to catering menus, photography styles to entertainment options - you have full control over your event details. Our event specialists can help you create the perfect custom package."
    },
    {
      question: "What is your cancellation policy?",
      answer: "Our cancellation policy is designed to be fair to both customers and vendors. Cancellations made 30 days or more before the event date are eligible for a full refund minus processing fees. For cancellations between 15-29 days, 70% refund is provided. Cancellations within 14 days are subject to our detailed policy terms, which vary by event type."
    }
  ];

  

  useEffect(() => {
    const img = new Image();
    img.src = '/bgimage.jpg';
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
      <AuthProvider>
       
       
       
    <div className="min-h-screen bg-white">
    <Header 
                isMenuOpen={isMenuOpen} 
                setIsMenuOpen={setIsMenuOpen} 
                activeItem={activeItem} 
                setActiveItem={setActiveItem} 
            />
            <div
  className={`relative min-h-[600px] bg-cover bg-center transition-opacity duration-1000 ${
    imageLoaded ? 'opacity-100' : 'opacity-0'
  }`}
  style={{
    backgroundImage: `linear-gradient(rgba(252, 123, 3, 0.7), rgba(252, 123, 3, 0.8)),url('/bgimage.jpg')`,
    backgroundAttachment: 'fixed',
  }}
>
      {/* Hero Section */}
      
        <div className="absolute inset-0 bg-black/20" />

        <div className="relative z-10 max-w-7xl mx-auto px-4 py-16">
          <h1 className="text-white text-3xl md:text-5xl font-bold text-center mb-12">
            {activeItem === 'Events' 
              ? "India's First Event Booking Site" 
              : activeItem === 'Photography'
              ? "Professional Photography Services"
              : "Serving Flavors – Your Perfect Catering Partner!"}
          </h1>

          {/* Search Form */}
          <div className="bg-white/95 backdrop-blur-sm rounded-lg shadow-xl p-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 transform transition-all duration-500 hover:scale-[1.02]">
            <div className="relative md:col-span-2 lg:col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {activeItem === 'Events' 
                  ? 'Event Type' 
                  : activeItem === 'Photography' 
                  ? 'Shoot Type' 
                  : 'Catering Type'}
              </label>
              <select
                className="w-full p-3 border rounded-lg appearance-none focus:border-[rgb(252,123,3)] focus:ring-1 focus:ring-[rgb(252,123,3)]"
                value={selectedEventType}
                onChange={(e) => setSelectedEventType(e.target.value)}
              >
                <option value="" className="whitespace-normal">
                  {activeItem === 'Events' 
                    ? 'Select Type of Event' 
                    : activeItem === 'Photography'
                    ? 'Select Type of Shoot'
                    : 'Select Catering Type'}
                </option>
                {(activeItem === 'Events' 
                  ? eventTypes 
                  : activeItem === 'Photography' 
                  ? shootTypes 
                  : cateringTypes).map((type) => (
                  <option key={type} value={type} className="whitespace-normal">
                    {type}
                  </option>
                ))}
              </select>
            </div>

            <div className="relative md:col-span-2 lg:col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">Location</label>
              <select
                className="w-full p-3 border rounded-lg appearance-none focus:border-[rgb(252,123,3)] focus:ring-1 focus:ring-[rgb(252,123,3)]"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                <option value="" className="whitespace-normal">Choose Location</option>
                {locations.map((loc) => (
                  <option key={loc} value={loc} className="whitespace-normal">
                    {loc}
                  </option>
                ))}
              </select>
            </div>

            <div className="relative md:col-span-2 lg:col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {activeItem === 'Catering' ? 'Number of Servings' : 'Budget'}
              </label>
              <input
                type="number"
                placeholder={
                  activeItem === 'Catering' 
                    ? 'Enter number of servings' 
                    : 'Enter your budget in thousands (₹)'
                }
                className="w-full p-3 border rounded-lg focus:border-[rgb(252,123,3)] focus:ring-1 focus:ring-[rgb(252,123,3)] placeholder:whitespace-normal placeholder:text-sm"
                value={activeItem === 'Catering' ? servings : budget}
                onChange={(e) => 
                  activeItem === 'Catering' 
                    ? setServings(e.target.value) 
                    : setBudget(e.target.value)
                }
              />
            </div>

            <div className="relative md:col-span-2 lg:col-span-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">Date</label>
              <input
                type="date"
                className="w-full p-3 border rounded-lg focus:border-[rgb(252,123,3)] focus:ring-1 focus:ring-[rgb(252,123,3)]"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          </div>

          
          <button 
  onClick={() => setIsOtpPopupOpen(true)} 
  className="mt-6 w-full py-4 rounded-lg font-medium transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg relative overflow-hidden group"
>
  <div className="absolute inset-0 bg-gradient-to-r from-[#fc7b03] via-[#ff9234] to-[#fc7b03] group-hover:bg-gradient-to-r group-hover:from-[#ff9234] group-hover:via-[#fc7b03] group-hover:to-[#ff9234] transition-all duration-300"></div>
  <span className="relative text-white text-lg">Confirm</span>
</button>

{/* Add popup component at the end of your return */}
<OTPVerificationPopup 
  isOpen={isOtpPopupOpen} 
  onClose={() => setIsOtpPopupOpen(false)} 
/>
          <CheesyEventLines />
        </div>
       
      </div>

      {/* Trending Offers */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-2xl font-bold">TRENDING OFFERS</h2>
         
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {trendingOffers.map((offer, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:scale-[1.02]"
            >
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <span className="text-3xl">{offer.icon}</span>
                  <span className="text-sm font-medium px-3 py-1 bg-orange-100 text-[rgb(252,123,3)] rounded-full">
                    {offer.tag}
                  </span>
                </div>
                <h3 className="font-medium mb-2">{offer.title}</h3>
                <p className="text-sm text-gray-500">{offer.validity}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Significance Section */}
      <div className="max-w-7xl mx-auto px-4 py-16 bg-gray-50">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-4xl font-bold text-[rgb(252,123,3)] mb-6">
              Significance of SHATA
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Event booking in India has become increasingly significant due to the country's burgeoning
              event industry. With a diverse population and a rising middle class, there is a growing demand
              for a wide range of events, from birthdays, weddings and corporate conferences to music
              festivals and cultural celebrations.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              Shata offers a convenient and efficient way to manage and organize events, attracting a large
              market of event planners, organizers, and attendees. As the Indian market continues to expand,
              the role of shata in streamlining processes, optimizing resources, and enhancing the overall
              event experience is set to grow even further.
            </p>
          </div>
          <div className="flex justify-center">
            <div className="rounded-lg overflow-hidden shadow-xl w-full">
              <img 
                src="/significance.jpg" 
                alt="Event Setup"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Coming Soon Section */}
      <div className="bg-[rgb(252,123,3)]">
        <div className="max-w-7xl mx-auto px-4 py-16">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <div className="inline-block bg-white rounded-lg px-6 py-3">
                <h3 className="text-[rgb(252,123,3)] text-2xl font-bold">COMING SOON</h3>
              </div>
              <h2 className="text-4xl font-bold text-white italic">
                Android and IOS Applications are on the way!
              </h2>
            </div>
            <div className="flex justify-center lg:justify-end">
              <img 
                src="/coming soon.jpg"
                alt="Mobile apps coming soon illustration"
                className="max-w-md w-full object-contain"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="px-[200px] py-[100px]">
      <div className="rounded-[40px] overflow-hidden">
        <img 
          src="/hj.png" 
          alt="HJ Image" 
          className="w-[1200px] h-[700px] object-cover"
        />
      </div>
    </div>

      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Frequently Asked Questions</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Get answers to common questions about our event booking platform. Can't find what you're looking for? Contact our support team.
          </p>
        </div>

        <div className="max-w-3xl mx-auto space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white rounded-xl overflow-hidden transition-all duration-300 hover:shadow-lg"
            >
              <button
                className="w-full text-left p-6 focus:outline-none focus:ring-2 focus:ring-[rgb(252,123,3)] focus:ring-opacity-50 relative"
                onClick={() => setOpenFaq(openFaq === index ? null : index)}
              >
                <div className="flex items-center justify-between">
                  <h3 className="font-medium text-lg pr-8">{faq.question}</h3>
                  <div 
                    className={`text-[rgb(252,123,3)] transition-transform duration-300 ${
                      openFaq === index ? 'rotate-180' : ''
                    }`}
                  >
                    {openFaq === index ? (
                      <Minus className="w-5 h-5" />
                    ) : (
                      <Plus className="w-5 h-5" />
                    )}
                  </div>
                </div>
              </button>
              
              <div 
                className={`overflow-hidden transition-all duration-300 ease-in-out ${
                  openFaq === index ? 'max-h-96' : 'max-h-0'
                }`}
              >
                <div className="p-6 pt-0 text-gray-600 border-t">
                  {faq.answer}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Contact Support Call-to-Action */}
        <div className="mt-12 text-center">
          <p className="text-gray-600 mb-4">Still have questions?</p>
          <button 
      onClick={() => window.location.href = 'tel:+919701811134'} 
      className="inline-flex items-center gap-2 px-6 py-3 bg-orange-500 text-white font-medium rounded-lg hover:bg-orange-600 transition-colors duration-300"
      >
      <PhoneIcon className="w-5 h-5" />
          Contact Support
          </button>
        </div>
      </div>

       
      <footer className="bg-gray-50 px-4 py-12 mt-auto">
  <div className="max-w-7xl mx-auto">
    {/* Footer Content */}
    <div className="flex flex-col md:flex-row md:justify-between gap-8">
      {/* Company Info */}
      <div className="md:w-1/3">
        <div className="mb-4">
          <img
            src="/shatalogo.png" // Update with your logo path
            alt="Company Logo"
            className="h-12"
          />
        </div>
        <p className="text-gray-600 text-sm">
          Shata is the India's First online event booking service. Shata offers event booking through its website, IOS and Android mobile apps.
        </p>
      </div>

      {/* Links Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 md:w-2/3">
        {/* About redBus */}
        <div>
          <h3 className="font-semibold text-lg mb-4">About Shata</h3>
          <ul className="space-y-2">
            <li><a href="#" className="text-gray-600 hover:text-red-500 text-sm">Contact us</a></li>
            <li><a href="#" className="text-gray-600 hover:text-red-500 text-sm">Mobile version</a></li> 
            <li><a href="#" className="text-gray-600 hover:text-red-500 text-sm">Offers</a></li> 
          </ul>
        </div>

        {/* Info Section */}
        <div>
          <h3 className="font-semibold text-lg mb-4">Info</h3>
          <ul className="space-y-2">
            <li><a href="/termsandconditions.html" className="text-gray-600 hover:text-red-500 text-sm">T&C</a></li>
            <li><a href="/legalpage.html" className="text-gray-600 hover:text-red-500 text-sm">Privacy Policy</a></li>
            <li><a href="#" className="text-gray-600 hover:text-red-500 text-sm">FAQ</a></li>
          
            
          </ul>
        </div>

        {/* Global Sites */}
        <div>
          <h3 className="font-semibold text-lg mb-4">Services</h3>
          <ul className="space-y-2">
            <li><a href="#" className="text-gray-600 hover:text-red-500 text-sm">Events</a></li>
            <li><a href="#" className="text-gray-600 hover:text-red-500 text-sm">Photography</a></li>
            <li><a href="#" className="text-gray-600 hover:text-red-500 text-sm">Catering</a></li>
            
          </ul>
        </div>
      </div>
    </div>

    {/* Copyright and Social Media */}
    <div className="mt-12 pt-8 border-t border-gray-200 flex flex-col md:flex-row justify-between items-center">
      <p className="text-gray-600 text-sm mb-4 md:mb-0">
        © 2024 Shata Pvt Ltd. All rights reserved
      </p>
      <div className="flex space-x-6">
        <a href="#" className="text-gray-600 hover:text-red-500">
          <i className="fab fa-facebook fa-lg"></i>
        </a>
        <a href="#" className="text-gray-600 hover:text-red-500">
          <i className="fab fa-linkedin fa-lg"></i>
        </a>
        <a href="#" className="text-gray-600 hover:text-red-500">
          <i className="fab fa-twitter fa-lg"></i>
        </a>
        <a href="https://www.instagram.com/shata_app/profilecard/?igsh=MWhiNDVreGRicTM2cA==" className="text-gray-600 hover:text-red-500">
          <i className="fab fa-instagram fa-lg"></i>
        </a>
      </div>
    </div>
  </div>
</footer>
</div>

</AuthProvider>
  );
};

const styles = `
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  .animate-slideDown {
    animation: slideDown 0.3s ease-out forwards;
  }

`;


export default EventBookingPlatform;