import React, { useState, useEffect } from 'react';
import { User, X } from 'lucide-react';
import { jwtDecode } from 'jwt-decode';
import { useAuth } from './AuthContext';


import { authService } from './authService';

export const LoginModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [isSignUpMode, setIsSignUpMode] = useState(false);
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = isSignUpMode 
        ? await authService.signup({ name, email, password })
        : await authService.login({ email, password });
      
      login(userData);
      onClose();
    } catch (error) {
      // Handle error (show error message)
      console.error('Authentication error:', error);
    }
  };

  const handleGoogleSignIn = async (googleResponse) => {
    try {
      const userData = await authService.googleSignIn(googleResponse);
      login(userData);
      onClose();
    } catch (error) {
      console.error('Google Sign-In error:', error);
    }
  };


  useEffect(() => {
    if (isOpen && window.google) {
      window.google.accounts.id.initialize({
        client_id: "942692995772-s56buuoniqeeeahqfqbtvuccclpfle8u.apps.googleusercontent.com", // Replace with your actual client ID
        callback: handleGoogleCredentialResponse,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("googleSignInButton"),
        { theme: "outline", size: "large" }
      );
    }
  }, [isOpen]);

  const handleLogin = async (e) => {
    e.preventDefault();
    // Simulated login - replace with actual authentication logic if necessary
    const userData = { name: 'User Name', email }; // Replace with actual user name
    await sendUserDataToBackend(userData);
    login(userData);
    onClose();
  };

  // In your existing code, modify handleGoogleCredentialResponse
const handleGoogleCredentialResponse = async (response) => {
  try {
    // Send the entire Google credential to backend for verification
    const backendResponse = await fetch('http://localhost:3000/api/google-signin', {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        credential: response.credential 
      })
    });

    if (!backendResponse.ok) {
      throw new Error('Google Sign-In verification failed');
    }

    const userData = await backendResponse.json();
    login(userData);
    onClose();
  } catch (error) {
    console.error("Google Sign-In Error:", error);
    alert("Failed to sign in with Google. Please try again.");
  }
};

 // Ensure your API call is correctly configured
const sendUserDataToBackend = async (userData) => {
  try {
    // Use the correct full URL or relative path
    const response = await fetch('/api/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData)
    });

    if (!response.ok) {
      // More detailed error handling
      const errorData = await response.json();
      throw new Error(errorData.message || 'Signup failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Detailed error:', error);
    throw error;
  }
};
  const handleSignUpToggle = () => {
    setIsSignUpMode(!isSignUpMode);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[100] flex items-center justify-center">
      <div className="bg-white rounded-lg p-8 w-96 relative">
        <button 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <X size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-6 text-center">
          {isSignUpMode ? 'Sign Up' : 'Login'}
        </h2>

        <form onSubmit={handleLogin} className="space-y-4">
          {isSignUpMode && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Name</label>
              <input
                type="text"
                required
                className="w-full p-3 border rounded-lg focus:border-[rgb(252,123,3)] focus:ring-1 focus:ring-[rgb(252,123,3)]"
              />
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full p-3 border rounded-lg focus:border-[rgb(252,123,3)] focus:ring-1 focus:ring-[rgb(252,123,3)]"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full p-3 border rounded-lg focus:border-[rgb(252,123,3)] focus:ring-1 focus:ring-[rgb(252,123,3)]"
            />
          </div>

          <button 
            type="submit" 
            className="w-full py-3 bg-[rgb(252,123,3)] text-white rounded-lg hover:bg-orange-600 transition-colors"
          >
            {isSignUpMode ? 'Sign Up' : 'Login'}
          </button>

          {/* Google Login Button */}
          <div id="googleSignInButton" className="w-full flex justify-center mt-4"></div>

          <p className="text-center text-sm mt-4">
            {isSignUpMode ? 'Already have an account? ' : "Don't have an account? "}
            <button 
              type="button"
              onClick={handleSignUpToggle}
              className="text-[rgb(252,123,3)] ml-1 hover:underline"
            >
              {isSignUpMode ? 'Login' : 'Sign Up'}
            </button>
          </p>
        </form>
      </div>
    </div>
  );
};