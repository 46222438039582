import React, { useState, useCallback, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  PhoneIcon, 
  LockClosedIcon, 
  XMarkIcon,
  CreditCardIcon 
} from '@heroicons/react/24/solid';

// Configuration constants
const PHONE_NUMBER_CONFIG = {
  length: 10,
  prefix: '+91',
  validStartingDigits: /^[6-9]\d{9}$/
};

const OTP_CONFIG = {
  length: 6,
  resendCountdown: 60
};

// Main Phone Verification Component
function PhoneVerification({ 
  isOpen, 
  onClose, 
  onVerificationSuccess,
  onPaymentInitiate 
}) {
  // State Management Hooks
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpDigits, setOtpDigits] = useState(
    new Array(OTP_CONFIG.length).fill('')
  );
  const [verificationStage, setVerificationStage] = useState('phone');
  const [countdown, setCountdown] = useState(OTP_CONFIG.resendCountdown);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  // Refs for input management
  const otpInputRefs = useRef([]);

  // Validation Helpers
  const validatePhoneNumber = useCallback((number) => {
    return PHONE_NUMBER_CONFIG.validStartingDigits.test(number);
  }, []);

  const validateOTP = useCallback((otp) => {
    return otp.every(digit => digit !== '') && otp.length === OTP_CONFIG.length;
  }, []);

  // Countdown Management
  useEffect(() => {
    let timer = null;

    if (verificationStage === 'otp' && countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [verificationStage, countdown]);

  // OTP Sending Handler
  const handleSendOTP = async () => {
    // Reset previous state
    setError(null);
    setIsLoading(true);

    try {
      // Simulated OTP sending (replace with actual Firebase logic)
      if (!validatePhoneNumber(phoneNumber)) {
        throw new Error('Invalid phone number');
      }

      // Simulate network delay
      await new Promise(resolve => setTimeout(resolve, 1500));

      setVerificationStage('otp');
      setCountdown(OTP_CONFIG.resendCountdown);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'OTP sending failed');
    } finally {
      setIsLoading(false);
    }
  };

  // OTP Verification Handler
  const handleVerifyOTP = async () => {
    setError(null);
    setIsLoading(true);

    try {
      // Validate OTP
      if (!validateOTP(otpDigits)) {
        throw new Error('Please enter a complete OTP');
      }

      // Simulated OTP verification (replace with actual Firebase logic)
      await new Promise(resolve => setTimeout(resolve, 1500));

      // Success actions
      setIsVerified(true);
      onVerificationSuccess?.();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'OTP verification failed');
    } finally {
      setIsLoading(false);
    }
  };

  // OTP Input Management
  const handleOTPChange = (index, value) => {
    // Only allow numeric input
    if (value !== '' && !/^\d$/.test(value)) return;

    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;
    setOtpDigits(newOtpDigits);

    // Auto-focus next input
    if (value && index < OTP_CONFIG.length - 1) {
      otpInputRefs.current[index + 1]?.focus();
    }
  };

  // Reset OTP Process
  const handleResendOTP = () => {
    setCountdown(OTP_CONFIG.resendCountdown);
    handleSendOTP();
  };

  // Handle Payment Initiation
  const handleInitiatePayment = () => {
    onPaymentInitiate?.();
    onClose();
  };

  // Render Phone Number Input
  const renderPhoneInput = () => (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="space-y-4"
    >
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Mobile Number
        </label>
        <div className="flex">
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
            {PHONE_NUMBER_CONFIG.prefix}
          </span>
          <input
            type="tel"
            maxLength={PHONE_NUMBER_CONFIG.length}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter mobile number"
            className="flex-1 block w-full rounded-r-md border-gray-300 focus:ring-2 focus:ring-orange-300 transition-all"
          />
        </div>
      </div>
      <button
        onClick={handleSendOTP}
        disabled={!validatePhoneNumber(phoneNumber) || isLoading}
        className={`w-full py-3 rounded-lg text-white transition-all ${
          validatePhoneNumber(phoneNumber) && !isLoading
            ? 'bg-orange-500 hover:bg-orange-600 active:scale-95'
            : 'bg-gray-400 cursor-not-allowed'
        }`}
      >
        {isLoading ? 'Sending...' : 'Send OTP'}
      </button>
    </motion.div>
  );

  // Render OTP Input
  const renderOTPInput = () => (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="space-y-4"
    >
      <div className="flex justify-between space-x-2">
        {otpDigits.map((digit, index) => (
          <motion.input
            key={index}
            type="text"
            maxLength={1}
            value={digit}
            ref={(el) => otpInputRefs.current[index] = el}
            onChange={(e) => handleOTPChange(index, e.target.value)}
            className="w-12 h-12 text-center border rounded-lg focus:ring-2 focus:ring-orange-300 transition-all"
            whileFocus={{ scale: 1.05 }}
          />
        ))}
      </div>

      <div className="flex justify-between items-center">
        {countdown > 0 ? (
          <span className="text-sm text-gray-500">
            Resend OTP in {countdown} seconds
          </span>
        ) : (
          <button
            onClick={handleResendOTP}
            className="text-orange-500 hover:underline active:scale-95 transition-all"
          >
            Resend OTP
          </button>
        )}
      </div>

      <button
        onClick={handleVerifyOTP}
        disabled={!validateOTP(otpDigits) || isLoading}
        className={`w-full py-3 rounded-lg text-white flex items-center justify-center transition-all ${
          validateOTP(otpDigits) && !isLoading
            ? 'bg-orange-500 hover:bg-orange-600 active:scale-95'
            : 'bg-gray-400 cursor-not-allowed'
        }`}
      >
        <LockClosedIcon className="w-5 h-5 mr-2" />
        {isLoading ? 'Verifying...' : 'Verify OTP'}
      </button>
    </motion.div>
  );

  // Render Pay Now Button
  const renderPayNowButton = () => (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}
      className="space-y-4"
    >
      <div className="text-center mb-4">
        <div className="mx-auto w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-4">
          <CreditCardIcon className="w-8 h-8 text-green-500" />
        </div>
        <h2 className="text-2xl font-bold text-gray-800">
          Phone Verified
        </h2>
        <p className="text-gray-600 mt-2">
          You can now proceed with payment
        </p>
      </div>

      <button
        onClick={handleInitiatePayment}
        className="w-full py-3 rounded-lg text-white bg-orange-500 hover:bg-orange-600 active:scale-95 transition-all flex items-center justify-center"
      >
        <CreditCardIcon className="w-5 h-5 mr-2" />
        Pay Now
      </button>
    </motion.div>
  );

  // Main Render
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-2xl p-6 w-full max-w-md relative"
          >
            {/* Close Button */}
            <button 
              onClick={onClose} 
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 active:scale-95 transition-all"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>

            {/* Header */}
            <div className="text-center mb-6">
              <div className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <PhoneIcon className="w-8 h-8 text-orange-500" />
              </div>
              <h2 className="text-2xl font-bold text-gray-800">
                {!isVerified && verificationStage === 'phone' 
                  ? 'Verify Phone Number' 
                  : !isVerified
                  ? 'Enter Verification Code'
                  : 'Proceed with Payment'}
              </h2>
            </div>

            {/* Error Message */}
            {error && (
              <motion.div 
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-red-50 text-red-700 p-3 rounded-lg mb-4 text-center"
              >
                {error}
              </motion.div>
            )}

            {/* Dynamic Rendering Based on Verification Stage */}
            {!isVerified && (
              verificationStage === 'phone' 
                ? renderPhoneInput() 
                : renderOTPInput()
            )}

            {/* Pay Now Button Appears After Verification */}
            {isVerified && renderPayNowButton()}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default PhoneVerification;