import React, { createContext, useState, useContext } from 'react';

// Translations for different components
const translations = {
  en: {
    code: 'en',
    name: 'English',
    translations: {
      // Header translations
      headerTitle: "India's First Event Booking Site",
      photographyTitle: "Professional Photography Services",
      cateringTitle: "Serving Flavors – Your Perfect Catering Partner!",
      
      // Form translations
      selectEventType: "Select Type of Event",
      selectShootType: "Select Type of Shoot",
      selectCateringType: "Select Catering Type",
      chooseLocation: "Choose Location",
      confirmButton: "Confirm",

      // Significance section
      significanceTitle: "Significance of SHATA",
      significanceParagraph1: "Event booking in India has become increasingly significant due to the country's burgeoning event industry. With a diverse population and a rising middle class, there is a growing demand for a wide range of events, from birthdays, weddings and corporate conferences to music festivals and cultural celebrations.",
      significanceParagraph2: "Shata offers a convenient and efficient way to manage and organize events, attracting a large market of event planners, organizers, and attendees. As the Indian market continues to expand, the role of shata in streamlining processes, optimizing resources, and enhancing the overall event experience is set to grow even further."
    }
  },
  hi: {
    code: 'hi',
    name: 'हिन्दी',
    translations: {
      headerTitle: "भारत की पहली इवेंट बुकिंग साइट",
      photographyTitle: "पेशेवर फोटोग्राफी सेवाएं",
      cateringTitle: "स्वाद परोसते – आपका परफेक्ट केटरिंग पार्टनर!",
      
      selectEventType: "इवेंट का प्रकार चुनें",
      selectShootType: "शूट के प्रकार का चयन करें",
      selectCateringType: "केटरिंग प्रकार चुनें",
      chooseLocation: "स्थान चुनें",
      confirmButton: "पुष्टि करें"
    }
  },
  ta: {
    code: 'ta',
    name: 'தமிழ்',
    translations: {
      headerTitle: "இந்தியாவின் முதல் நிகழ்வு முன்பதிவு தளம்",
      photographyTitle: "தொழில்முறை புகைப்பட சேவைகள்",
      cateringTitle: "சுவைகளைப் பரிமாறுதல் – உங்கள் சரியான கேட்டரிங் கூட்டாளி!",
      
      selectEventType: "நிகழ்வு வகையைத் தேர்ந்தெடுக்கவும்",
      selectShootType: "ஷூட் வகையைத் தேர்ந்தெடுக்கவும்",
      selectCateringType: "கேட்டரிங் வகையைத் தேர்ந்தெடுக்கவும்",
      chooseLocation: "இடத்தைத் தேர்ந்தெடுக்கவும்",
      confirmButton: "உறுதிப்படுத்து"
    }
  },
  te: {
    code: 'te',
    name: 'తెలుగు',
    translations: {
      headerTitle: "భారతదేశంలోని తొలి ఈవెంట్ బుకింగ్ సైట్",
      photographyTitle: "వృత్తిపరమైన ఫోటోగ్రాఫీ సేవలు",
      cateringTitle: "సిద్ధం చేసిన వంటకాలు – మీ సరైన కేటరింగ్ భాగస్వామి!",
      
      selectEventType: "ఈవెంట్ రకాన్ని ఎంచుకోండి",
      selectShootType: "షూట్ రకాన్ని ఎంచుకోండి",
      selectCateringType: "కేటరింగ్ రకాన్ని ఎంచుకోండి",
      chooseLocation: "స్థలాన్ని ఎంచుకోండి",
      confirmButton: "నిర్ధారించు"
    }
  },
  kn: {
    code: 'kn',
    name: 'ಕನ್ನಡ',
    translations: {
      headerTitle: "ಭಾರತದ ಮೊಟ್ಟಮೊದಲ ಕಾರ್ಯಕ್ರಮ ಬುಕಿಂಗ್ ಜಾಲತಾಣ",
      photographyTitle: "ಮಾಹಿತಿ ಸ್ನೇಹಿ ಫೋಟೋಗ್ರಾಫಿ ಸೇವೆಗಳು",
      cateringTitle: "ಊಟವನ್ನು ಸಿದ್ಧಗೊಳಿಸುವುದು – ನಿಮ್ಮ ಸರಿಯಾದ ಕೇಟರಿಂಗ್ ಪಾಲುದಾರ!",
      
      selectEventType: "ಕಾರ್ಯಕ್ರಮ ಬಗೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      selectShootType: "ಶೂಟ್ ಬಗೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      selectCateringType: "ಕೇಟರಿಂಗ್ ಬಗೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      chooseLocation: "ಸ್ಥಳವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
      confirmButton: "ದೃಢೀಕರಿಸಿ"
    }
  },
  ml: {
    code: 'ml',
    name: 'മലയാളം',
    translations: {
      headerTitle: "ഇന്ത്യയിലെ ആദ്യത്തെ ഇവന്റ് ബുക്കിംഗ് സൈറ്റ്",
      photographyTitle: "വിദഗ്ദ്ധ ഫോട്ടോഗ്രഫി സേവനങ്ങൾ",
      cateringTitle: "സ്വാദം പരിമാറുന്നു – നിങ്ങളുടെ തികഞ്ഞ കേറ്ററിംഗ് പങ്കാളി!",
      
      selectEventType: "ഇവന്റ് തരം തിരഞ്ഞെടുക്കുക",
      selectShootType: "ഷൂട്ട് തരം തിരഞ്ഞെടുക്കുക",
      selectCateringType: "കേറ്ററിംഗ് തരം തിരഞ്ഞെടുക്കുക",
      chooseLocation: "സ്ഥലം തിരഞ്ഞെടുക്കുക",
      confirmButton: "സ്ഥിരീകരിക്കുക"
    }
  }
};

// Create Language Context
const LanguageContext = React.createContext({
  language: translations['en'],
  setLanguage: () => {},
  t: (key) => key
});

// Language Provider Component
export const LanguageProvider = ({ children }) => {
  const [language, setLanguageState] = useState(translations['en']);

  // Enhanced language setting with error handling
  const setLanguage = (languageCode) => {
    const selectedTranslation = translations[languageCode];
    
    if (selectedTranslation) {
      setLanguageState(selectedTranslation);
    } else {
      console.warn(`Language code ${languageCode} not found. Defaulting to English.`);
      setLanguageState(translations['en']);
    }
  };

  // Translation function with missing key warning
  const t = (key) => {
    const translation = language.translations[key];
    
    if (!translation) {
      console.warn(`Missing translation for key: ${key} in ${language.code}`);
    }
    
    return translation || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook for using language context
export const useLanguage = () => {
  const context = useContext(LanguageContext);
  
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  
  return context;
};

export { translations };