import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { User } from 'lucide-react';

// Authentication Context
const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  isAuthenticated: false
});

// Authentication Provider Component
export const AuthProvider = ({ children }) => {
  // State to manage user and authentication status
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check for existing authentication on component mount
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        
        // Validate token if exists
        if (parsedUser.token) {
          const decodedToken = jwtDecode(parsedUser.token);
          
          // Check token expiration
          if (decodedToken.exp && decodedToken.exp * 1000 > Date.now()) {
            setUser(parsedUser);
            setIsAuthenticated(true);
          } else {
            // Clear expired token
            localStorage.removeItem('user');
            setIsAuthenticated(false);
          }
        }
      } catch (error) {
        // Handle parsing or decoding errors
        console.error('Authentication error:', error);
        localStorage.removeItem('user');
        setIsAuthenticated(false);
      }
    }
  }, []);

  // Login function to set user and store in local storage
  const login = (userData) => {
    try {
      // Validate token before setting user
      jwtDecode(userData.token);
      
      setUser(userData);
      setIsAuthenticated(true);
      localStorage.setItem('user', JSON.stringify(userData));
    } catch (error) {
      console.error('Invalid token during login', error);
      // Handle invalid token scenario
    }
  };

  // Logout function to clear user data
  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem('user');
  };

  // Provide context values to children
  return (
    <AuthContext.Provider value={{ 
      user, 
      login, 
      logout, 
      isAuthenticated 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  
  return context;
};

// User Profile Component
export const UserProfile = ({ onLogoutClick }) => {
  const { user, logout } = useAuth();

  // Don't render if no user is logged in
  if (!user) return null;

  return (
    <div className="flex items-center space-x-4">
      <div className="flex items-center">
        {user.profilePicture ? (
          <img
            src={user.profilePicture}
            alt="Profile"
            className="w-8 h-8 rounded-full mr-2 object-cover"
          />
        ) : (
          <User className="mr-2" />
        )}
        <span>{user.name || user.email}</span>
      </div>
      <button
        onClick={() => {
          logout();
          onLogoutClick && onLogoutClick();
        }}
        className="text-[rgb(252,123,3)] hover:underline"
      >
        Logout
      </button>
    </div>
  );
};