import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { User, LogIn, LogOut } from 'lucide-react';
import { useAuth } from './AuthContext';

export const UserDropdown = ({ onLoginClick }) => {
    const { user, logout } = useAuth();

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="flex items-center space-x-2 px-4 py-2 rounded-lg hover:text-[rgb(252,123,3)] text-lg transform hover:scale-105 transition-all">
                    <User size={24} />
                    <span>{user ? 'My Account' : 'Account'}</span>
                </Menu.Button>
            </div>

            <Transition
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {user ? (
                            <>
                                <Menu.Item>
                                    <button
                                        onClick={logout}
                                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                    >
                                        <LogOut className="mr-2 h-5 w-5" />
                                        Logout
                                    </button>
                                </Menu.Item>
                                {/* Add more user-specific options here if needed */}
                            </>
                        ) : (
                            <Menu.Item>
                                <button
                                    onClick={onLoginClick} // Call the passed function to open the login modal
                                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                                >
                                    <LogIn className="mr-2 h-5 w-5" />
                                    Login
                                </button>
                            </Menu.Item>
                        )}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};